export default () => {
    const config = useConfig();
    const route = useRoute();
    const router = useRouter();
    const comprehensive = computed({
        get() {
            return config.pronouns.comprehensive ? Object.hasOwn(route.query, config.pronouns.comprehensive) : false;
        },
        set(value) {
            if (value === comprehensive.value || !config.pronouns.comprehensive) {
                // prevent warning that $router.replace has no effect
                return;
            }
            const query = structuredClone(route.query);
            if (value) {
                query[config.pronouns.comprehensive] = null;
            } else {
                delete query[config.pronouns.comprehensive];
            }
            router.replace({ query });
        },
    });
    return comprehensive;
};
