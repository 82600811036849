<template>
    <li v-if="pronoun" class="my-1">
        <Example :example="example" :pronoun="pronoun" :counter="counter" :link="link" :pronunciation="pronunciation" />
        <Tooltip :text="tooltipText">
            <button
                v-if="hasDifferentExample"
                type="button"
                class="btn btn-sm btn-link px-1 py-0"
                @click="selectDifferentExample()"
            >
                <Icon v="random" />
            </button>
        </Tooltip>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { ExampleCategory } from '../src/classes.ts';
import type { Example, Pronoun } from '../src/classes.ts';
import { randomItem } from '../src/helpers.ts';

export default defineComponent({
    props: {
        exampleCategory: { required: true, type: ExampleCategory },
        pronounsChoice: { required: true, type: Array as PropType<Pronoun[]> },
        counter: { default: 0, type: Number },
        link: { type: Boolean },
        pronunciation: { type: Boolean },
    },
    data() {
        return {
            pronoun: null as Pronoun | null,
            example: this.exampleCategory.examples[0],
        };
    },
    computed: {
        suitableExamples(): Example[] {
            return this.exampleCategory.examples.filter((example) => {
                return this.pronounsChoice.some((pronoun) => example.requiredMorphemesPresent(pronoun, this.counter));
            });
        },
        hasDifferentExample(): boolean {
            return this.pronounsChoice.length > 1 || this.suitableExamples.length > 1;
        },
        tooltipText(): string {
            if (this.exampleCategory.name) {
                return this.$t('pronouns.examples.shuffleNamed', { category: this.exampleCategory.name });
            }
            return this.$t('pronouns.examples.shuffle');
        },
    },
    created() {
        this.selectPronounForExample(false);
    },
    methods: {
        selectDifferentExample(): void {
            const changeExample = this.suitableExamples.length > 1;
            if (changeExample) {
                const differentExamples = this.suitableExamples.filter((example) => example !== this.example);
                this.example = randomItem(differentExamples);
            }
            this.selectPronounForExample(!changeExample);
        },
        selectPronounForExample(forceDifferent: boolean): void {
            const suitablePronouns = this.pronounsChoice.filter((pronoun) => {
                return this.example.requiredMorphemesPresent(pronoun) && (!forceDifferent || pronoun !== this.pronoun);
            });
            this.pronoun = randomItem(suitablePronouns);
        },
    },
});
</script>
