<template>
    <div v-if="config.pronouns.comprehensive" class="btn-group" role="group">
        <button
            :class="['btn btn-sm', !modelValue ? 'btn-primary' : 'btn-outline-primary']"
            @click="$emit('update:modelValue', false)"
        >
            <T>pronouns.comprehensive.simple</T>
        </button>
        <button
            :class="['btn btn-sm', modelValue ? 'btn-primary' : 'btn-outline-primary']"
            @click="$emit('update:modelValue', true)"
        >
            <T>pronouns.comprehensive.comprehensive</T>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';

export default defineComponent({
    props: {
        modelValue: { default: false, type: Boolean },
    },
    emits: ['update:modelValue'],
    setup() {
        return {
            config: useConfig(),
        };
    },
});
</script>
